body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ag-row-odd {
    background-color: #f5f5f5;
}

.data-grid-container .data-grid {
    width: 100%;
}

.data-grid-container .data-grid .cell .value-viewer,
.data-grid-container .data-grid .cell .data-editor {
    width: 100%;
    height: 100%;
}

.data-grid .data-body .data-row > .cell > input.data-editor {
    outline: none !important;
    border: 2px solid rgb(33, 133, 208);
    text-align: right;
    width: calc(100% - 6px);
    height: 11px;
    background: none;
    display: block;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ant-menu-item span[role=img] {
    text-align: left;
    width: 25px;
}